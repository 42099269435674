<template>
	<div class="invoiceProcess">
		<div class="banner explain"><img :src="kpIcon" alt=""><span>发票系统流程使用说明</span></div>
		<div class="billpath">
			<div class="billpath_nav clear">
				<div class="billpath_navleft">
					<span v-for="(item,index) in navList" :key="index" @click="jumpLoc(item.id)">{{item.text}}</span>
				</div>
				<span class="billpath_jump lingred continue_explain" @click="nextPage">跳过说明</span>
			</div>
			<span>注：如有不明白，请咨询QQ客服：<em>3092547572</em></span>
			<div class="billpath_list">
				<div class="billpath_item billa">
					<p class="billpath_title" id="#processa">一、申请开票</p>
					<p><img :src="kpOne" alt="" class="billpath_index"><span>登录后，点击账号图标<img :src="manIcon" >，在下拉菜单中选择“查看订单”。</span></p>
					<img :src="kp1" alt="" class="billpath_img">
					<p><img :src="kpTwo" alt="" class="billpath_index"><span>在您需要发票的订单右上方点击“申请开票”。</span></p>
					<img :src="kp2" alt="" class="billpath_img">
					<p><img :src="kpThree" alt="" class="billpath_index"><span>在新页面中勾选您需要发票的书籍。</span></p>
					<img :src="kp3" alt="" class="billpath_img">
					<p><img :src="kpFour" alt="" class="billpath_index"><span>在填写信息页面中请先选择发票抬头为“<em>个人</em>”或“<em>单位</em>”，填写完成后点击页面下方“申请开票”。</span></p>
					<p class="billpath_ltitle">（4.1）输入个人信息</p>
					<img :src="kp4" alt="" class="billpath_img">
					<p class="billpath_ltitle">（4.2）输入单位信息</p>
					<img :src="kp5" alt="" class="billpath_img">
					<p><img :src="kpFive" alt="" class="billpath_index"><span>出现此页面表示发票申请成功。</span></p>
					<img :src="kp6" alt="" class="billpath_img">
				</div>
				<div class="billpath_item billb">
					<p class="billpath_title" id="#processb">二、查看发票或重发邮箱</p>
					<p><img :src="kpOne" alt="" class="billpath_index"><span>进入订单查询页面，点击右上“查看发票”。</span></p>
					<img :src="kp7" alt="" class="billpath_img">
					<p><img :src="kpTwo" alt="" class="billpath_index"><span>在已开票记录中点击“查看发票”。</span></p>
					<img :src="kp8" alt="" class="billpath_img">
					<p><img :src="kpThree" alt="" class="billpath_index"><span>电子发票展示页面。</span></p>
					<img :src="kp9" alt="" class="billpath_img">
					<p><img :src="kpFour" alt="" class="billpath_index"><span>若您需将该发票重新发送至您指定邮箱，请点击“重发邮箱”，输入邮箱地址，并点击确认。</span></p>
					<img :src="kp10" alt="" class="billpath_img">
					<img :src="kp11" alt="" class="billpath_img">
				</div>
				<div class="billpath_item billc">
					<p class="billpath_title" id="#processc">三、换开申请</p>
					<p><span class="billpath_wrong">若您之前填写的发票信息有误需要重新开具发票，首先请在此页面点击“换开申请”，此操作将冲销您之前的错误发票。注销完成后，请您重新执行开票流程。<em>（注意：每笔订单最多执行3次注销操作，请您认真填写发票信息。）</em></span></p>
					<img :src="kp12" alt="" class="billpath_img">
				</div>
				<span id="billpath_btn" class="continue_explain" @click="nextPage">开始填写发票信息</span>
			</div>
		</div>
	</div>
</template>

<script>	
	export default{
		name:'process',
		data:()=>{
			return{
				kpIcon:require('@/assets/images/kaipiao.png'),
				kpOne:require('@/assets/images/one.png'),
				kpTwo:require('@/assets/images/two.png'),
				kpThree:require('@/assets/images/three.png'),
				kpFour:require('@/assets/images/four.png'),
				kpFive:require('@/assets/images/five.png'),
				kp1:require('@/assets/images/kp1.png'),
				kp2:require('@/assets/images/kp2.png'),
				kp3:require('@/assets/images/kp3.png'),
				kp4:require('@/assets/images/kp4.png'),
				kp5:require('@/assets/images/kp5.png'),
				kp6:require('@/assets/images/kp6.png'),
				kp7:require('@/assets/images/kp7.png'),
				kp8:require('@/assets/images/kp8.png'),
				kp9:require('@/assets/images/kp9.png'),
				kp10:require('@/assets/images/kp10.jpg'),
				kp11:require('@/assets/images/kp11.jpg'),
				kp12:require('@/assets/images/kp12.jpg'),
				manIcon:require('@/assets/images/man.png'),
				navList:[
					{id:'a',text:'一、申请开票'},
					{id:'b',text:'二、查看发票或重发邮箱'},
					{id:'c',text:'三、换开申请'}
				]
			}
		},
		created() {
			sessionStorage.removeItem('invoiceInfo')
		},
		methods:{
			// 点击导航
			jumpLoc:function (e) {
				let targetbox = document.getElementById('#process' + e).offsetTop - 200;
				let top = 0;
				document.documentElement.scrollTop = targetbox;
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top += 100;
					if (top >= targetbox) {
						clearInterval(timeTop);
					}
				}, 10);
			},
      
      /**
       * ####################################################################################################
       * ## 下一步
       * ####################################################################################################
       */
      nextPage: function() {
        this.$router.push({
          path: '/place/invoiceTwo',
          query: { order_no:  this.$route.query.order_no }
        });
      }
		}
	}
</script>

<style scoped>
.explain{ background: rgb(63,166,210);background: linear-gradient(107deg,rgb(63,166,210),rgb(127,215,82));height: 320px;text-align: center;width: 100%;}
.explain img{vertical-align: middle;display: inline-block;margin-right: 20px;width: 73px;height: 73px;min-width: 73px;line-height: 320px;}
.explain span{vertical-align: middle;display: inline-block;font-size: 36px;color: #FFFFFF;line-height: 320px;}
.billpath{max-width: 1200px;min-width: 1000px;margin: 0 auto;font-size: 16px;}
.billpath em{color: #3B77D1;}
.billpath_img{width: 100%;margin: 30px 0 40px;}
.billpath_nav{margin: 34px 0 20px;width: 100%;}
.billpath_nav .billpath_navleft{float: left;width: 760px;}
.billpath_nav .billpath_navleft span{display: inline-block;cursor: pointer;margin-left: 4%;font-size: 18px;color: #fff;text-align: center;padding: 10px;width: 228px;background: rgb(63,122,210); background:linear-gradient(-90deg,rgb(63,122,210) 0%,rgb(63,166,210) 100%);}
.billpath_nav .billpath_navleft span:hover{opacity: 0.9;}
.billpath_nav .billpath_navleft span:first-child{margin: 0;}
.billpath_jump{float: right;width: 200px;height: 46px;text-align: center;line-height: 46px;cursor: pointer;border-radius: 24px;font-size: 20px;}
.billpath_list{padding-bottom: 30px;}
.billpath_item{margin-top: 40px;}
.billpath_item .billpath_title{font-size: 30px;color: #333;margin-bottom: 25px;border-bottom: 1px dashed #eee;line-height: 2;font-weight: bold;}
.billpath_item p{font-size: 24px;margin-bottom: 15px;}
.billpath_item p img{display: inline-block;vertical-align: middle;}
.billpath_item p span{display: inline-block;vertical-align: middle;line-height: 1.6;}
.billpath_item p img{margin: 0 8px;}
.billpath_item p .billpath_index{width: 50px;margin-right: 10px;margin-left: 0;}
.billpath_ltitle{font-size: 20px;color: #333;line-height: 2;}
.billpath_item p .billpath_wrong{line-height: 1.6;text-indent: 2em;}
#billpath_btn{width:320px;height:70px;margin:20px auto;display: block;text-align: center;cursor: pointer;background:rgb(63,122,210);background:linear-gradient(90deg,rgb(63,122,210) 0%,rgb(63,166,210) 100%);border-radius:35px;font-size:24px;color:#FFFFFF;line-height:70px;}
#billpath_btn:hover{color: rgb(127,215,82);}
</style>
